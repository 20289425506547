@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(226, 232, 240, 0.7);
}

body.dark {
  background: #000423;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* inine edit */
.rs-input {
  padding: 5px 10px;
  border: 1px solid #2563eb;
  background: transparent;
  border-radius: 5px;
  outline: none;
}
.rs-inline-edit-controls.rs-stack {
  display: flex;
  width: 50px;
}

/* landing page font */
.saira-condensed-semibold {
  font-family: 'Saira Condensed', serif;
  font-weight: 600;
  font-style: normal;
}

.saira-condensed-bold {
  font-family: 'Saira Condensed', serif;
  font-weight: 700;
  font-style: normal;
}

.saira-condensed-extrabold {
  font-family: 'Saira Condensed', serif;
  font-weight: 800;
  font-style: normal;
}

.react-player {
  width: 365px !important;
  height: 697px !important;
  border: 1px solid #fff;
}

@media screen and (max-width: 767px) {
  .react-player {
    width: 300px !important;
    height: 573px !important;
    border-color: rgb(161 161 170);
  }
}

/* react-tag-input-component */
.rti--container * {
  box-sizing: border-box;
  transition: all 0.2s ease;
}

.rti--container {
  --rti-bg: #000423;
  --rti-border: #374151;
  --rti-main: #374151;
  --rti-radius: 0.375rem;
  --rti-s: 0.5rem;
  --rti-tag: #34d399;
  --rti-tag-remove: #000423;
  --rti-tag-padding: 0.15rem 0.25rem;
  
  /* Container styles */
  align-items: center;
  background: var(--rti-bg);
  border: 1px solid var(--rti-border);
  border-radius: var(--rti-radius);
  display: flex;
  flex-wrap: wrap;
  gap: var(--rti-s);
  line-height: 1.4;
  padding: var(--rti-s);
}

.rti--container:focus-within {
  border-color: var(--rti-border);
  box-shadow: initial;
}

.rti--input {
  background: initial;
  color: #fff;
  border: 0;
  outline: 0;
  font-size: inherit;
  line-height: inherit;
  width: 40%;
}

.rti--tag {
  color: #000423;
  align-items: center;
  background: var(--rti-tag);
  border-radius: var(--rti-radius);
  display: inline-flex;
  justify-content: center;
  padding: var(--rti-tag-padding);
}

.rti--tag button {
  background: none;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  line-height: inherit;
  padding: 0 var(--rti-s);
}

.rti--tag button:hover {
  color: var(--rti-tag-remove);
}